<template>
  <div class="container-fluid mt-3">
    <Form @submit="saveNews" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الأخبار العاجلة' , path: '/breaking-news'}" current_page="إضافة خبر عاجل"/>

        <div class="controls-btns d-flex gap-2 mb-4">
          <button class="btn btn-publish" type="submit" @click="saveNews" :disabled="loading">إضافة</button>
        </div>
      </div>

      <el-card class="custom-card">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="تفاصيل الخبر" name="newsDetails">
            <div class="row my-3">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="content">عنوان الخبر</label>
                  <Field
                      name="content"
                      id="content"
                      v-model="news.content"
                      placeholder="عنوان الخبر"
                      class="form-control form-control-solid"
                      :rules="isRequired"
                      :class="{'border-red' : errors.content || errorLength}"
                  />
                  <span class="text-error">{{ errors.content ? 'الرجاء ادخال عنوان الخبر' : '' }}</span>
                  <span class="text-error">{{ errorLength ? 'يجب ألا يقل المحتوى عن 15 حرفًا' : '' }}</span>
                </div>

                <div class="form-group mt-3">
                  <label for="time_to_live">مدة ظهور الخبر</label>
                  <input
                      type="number"
                      min="1"
                      name="time_to_live"
                      id="time_to_live"
                      v-model="news.time_to_live"
                      placeholder="مدة ظهور الخبر بالدقائق"
                      class="form-control form-control-solid"
                      :rules="isRequired"
                      :class="{'border-red' : errors.time_to_live}"
                  />
                  <span class="text-error">{{ errors.time_to_live ? 'الرجاء ادخال مدة ظهور الخبر' : '' }}</span>
                </div>
                <div class="mt-3">
                  <label class="d-block" for="time_to_live">خصائص الخبر العاجل</label>
                  <el-checkbox v-model="news.is_active" class="me-0 mb-3 rounded-0" label="تفعيل؟"
                               border></el-checkbox>
                </div>

              </div>
            </div>
          </el-tab-pane>

        </el-tabs>
      </el-card>
    </Form>
  </div>

</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Field, Form} from "vee-validate";
import {createBreakingNews} from "@/services/breaking_news";

export default {
  name      : "AddBreakingNews",
  components: {
    Breadcrumb,
    Form,
    Field
  },
  data()
  {
    return {
      activeTab: "newsDetails",
      errorLength:false,
      loading: false,
      news     : {
        content    : '',
        is_active   : false,
        time_to_live: 10,
      }
    }
  },
  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    saveNews()
    {
      this.errorLength = false

      if(this.news.content.length < 15) {
        this.errorLength = true
      }else {
        this.loading = true
        createBreakingNews(this.news)
        .then(res=>{
          this.loading = false
          console.log(res)
          this.$router.push('/breaking-news')
        })
        .catch(()=>{
          this.loading = false
        })
      }
    }
  }
}
</script>

<style>

.custom-card {
  min-height: 300px;
}
.el-tabs__nav {
  float: right;
}

.el-tabs__item {
  padding-right: 0;
  padding-left: 20px !important;
}

.el-checkbox.is-bordered {
  margin: 0;
  border-radius: 0;
  height: 42px;
}

.el-checkbox__label {
  padding-right: 8px;

}

</style>
